import React, { useState } from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import Config from '../../config/config';

export default function LostPassword(props) {

    const [email, setEmail] = useState('');
    const [error, setError] = useState(false)

    function getPassword(evt) {
        evt.preventDefault();
        // Request API.
        axios.post(Config.apiURL + '/auth/forgot-password', {
            email: email,
            url:Config.apiURL + '/resetpassword',
        })
        .then(response => {
            // Handle success.
            setError('E-mail sikeresen elküldve az ön által megadott e-mail cimre. További teendők leirva az e-mailben.')
        })
        .catch(error => {
            // Handle error.
            console.log('An error occurred:', error.response);
        });
    }
    
    function redirect(evt) {
        evt.preventDefault();
        navigate('/pass-reset')
    }


    return(
        <div className="login-form">
            <div className="section-title">
                <h2>Elfelejtett jelszó</h2>
            </div>
            <form>
                <div className="form-group">
                    <label>E-mail</label>
                    <input type="email" value={email} className="form-control" placeholder="Kérem adja meg az e-mail cimét" onChange={(evt) => setEmail(evt.target.value)}/>
                </div>
                <button className="new-password-button" onClick={(evt) => getPassword(evt)} disabled={!email.length}>Új jelszó igénylése</button>
                <button className="back-button" onClick={() => props.setLostPassword(false)}>Vissza</button>
            </form>
            {error && <p style={{textAlign:'center', maxWidth:'400px', margin:'auto'}}>{error}</p>}
        </div>
    );
}