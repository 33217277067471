import React, {useEffect, useState} from 'react'
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { navigate } from "gatsby";
import { setIsLoggedIn, setUser } from "../../state/createStore";
import { connect, useDispatch } from "react-redux";
import { Link } from 'gatsby';
import Config from '../../config/config'

function LoginForm(props) {
    
    const [username, setUsername] = useState(false);
    const [password, setPassword] = useState(false);
    const [error, setError] = useState(false);

    let cookies = new Cookies();
    const dispatch = useDispatch();

    useEffect(() => {
        if(error) {
            setTimeout(() => {
                setError(false);
            }, 4000)
        }
    }, [error])

    function login(evt) {
        evt.preventDefault();

        // Request API.
        axios.post(Config.apiURL + '/auth/local', {
            identifier: username,
            password: password,
        }).then(response => {
            // Handle success.
            cookies.set('token', response.data.jwt, { path: '/'});
            // Required because if user has sub, the react-cookie not setting the cookie
            //const userDataString = JSON.stringify(response.data.user);
            //document.cookie = `user=${userDataString}; path=/`;
            //cookies.set('user', userDataString, { path: '/'});
            dispatch(setUser(response.data.user));
            dispatch(setIsLoggedIn());
            //localStorage.setItem('user', userDataString);
            navigate("/app/profile");

        }).catch(error => {
            // Handle error.
            console.log('An error occurred:', error);
            setError('Bejelentkezés sikertelen. Hibás felhasználó név vagy jelszó');
        });
    }


    return (
        <div className="login-form">
            <div className="section-title">
                <h2>Belépés</h2>
            </div>
            <form>
                <div className="form-group">
                    <label>Email</label>
                    <input type="email" name="email" className="form-control" placeholder="E-mail" onChange={(evt) => setUsername(evt.target.value)}/>
                </div>

                <div className="form-group">
                    <label>Jelszó</label>
                    <input type="password" name="password" className="form-control" placeholder="Jelszó" onChange={(evt) => setPassword(evt.target.value)}/>
                </div>
                <button type="submit" onClick={(evt) => login(evt)}>Belépés</button>
                <div className="additional-links d-flex justify-content-between">
                    <Link to="/" activeClassName="active"  className="nav-link">Regisztráljon most !</Link>
                    <a className="lost-pw-link nav-link" onClick={() => props.setLostPassword(true)}>Elfelejtette a jelszavát ?</a>
                </div>
            </form>
            {error && <div className="error-message-container">
                <p style={{textAlign:'center', color:'red', padding:'15px'}}>{error}</p>
           </div>}
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
	setLogin: () => {
        dispatch(setIsLoggedIn());
    },
    setUser: (user) => {
        dispatch(setUser(user))
    }
});

export default connect(
    null, 
    mapDispatchToProps
)(LoginForm)