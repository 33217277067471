import React, { useRef, useEffect, useState } from 'react';
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Header from '../components/Index/Header';
import Footer from "../components/App/Footer";
import LoginForm from '../components/ProfileAuthentication/LoginForm';
import LostPassword from '../components/ProfileAuthentication/LostPassword';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from "react-redux";
import { getIsLogedIn } from '../state/createStore';
import { navigate } from 'gatsby';

function ProfileAuthentication(props) {

    const [registered, setRegistered] = useState(false);
    const [lostPassword, setLostPassword] = useState(false);
    const ref = useRef(false);

    useEffect(() => {
        if (props.loggedIn) {
            navigate("/");
        }

        if(props.location.state !== null) {
            if(props.location.state.registration) {
                setRegistered(true);
                setTimeout(() => {
                    setRegistered(false);
                }, 10000)
            }
        }
    }, [])

    // run this function from an event handler or an effect to execute scroll 
    useEffect(() => {
        if(!props.loggedIn && ref.current) {
            setTimeout(() => {
                ref.current.scrollIntoView({ behavior: 'smooth' })
            }, 10)
       }
    }, [])

    return (
        <Layout>
            <Navbar />
            <div className='placeholder-area' />
            <section className="profile-authentication-area ptb-100">
                <Container className="">
                    <Row className="" ref={ref}>
                    {lostPassword ? 
                        <Col lg={12} className="">
                            <LostPassword setLostPassword={setLostPassword}/>
                        </Col>
                        :
                        <Col lg={12} className="">
                            <LoginForm setLostPassword={setLostPassword} />
                            {registered && <p style={{textAlign:'center', color:'green', paddingTop:'10px'}}>Sikeres regisztráció, kérjük jelentkezz be !</p>}
                        </Col>}
                    </Row>
                </Container>
            </section>
            <Footer />
        </Layout>
    )
}

const mapStateToProps = (state) => ({
    loggedIn:getIsLogedIn(state)
});

export default connect(
    mapStateToProps, 
    null
)(ProfileAuthentication)